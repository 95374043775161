<template>
    <div>
        <label
            v-if="label"
            :for="props?.id"
            :class="['label',borderedClasses.label,
                     requiredClasses.label,
                     disabledClasses.label]"
        ><span class="label-text">{{ props?.label }}</span>
        </label>
        <select
            :id="props?.id"
            :class="[
                'px-3 py-2 focus:ring-0',
                'w-full rounded-md text-sm focus:border focus:border-solid focus:border-main-400',
                borderedClasses.input,
                requiredClasses.input,
                disabledClasses.input
            ]"
            :disabled="props?.disabled"
            :required="props?.required"
            @change="selectUpdated"
        >
            <option
                v-for="option of props?.options"
                :key="option.text + option.value"
                :value="option.value"
                :selected="option.selected"
            >
                {{ option.text }}
            </option>
        </select>
    </div>
</template>

<script setup lang="ts">
import { type selectInputOption, type inputStates } from '../types/selectInputOption';
const states = {
    default: 'default',
    disabled: 'disabled',
    required: 'required',
    bordered: 'bordered'
};
const props = defineProps({
    label: {
        type: String,
        default: ''
    },
    id: {
        type: String,
        default: 'name'
    },
    disabled: {
        type: Boolean,
        default: false
    },
    required: {
        type: Boolean,
        default: false
    },
    bordered: {
        type: Boolean,
        default: true
    },
    options: {
        type: Array<selectInputOption>,
        required: true
    }
});
const emit = defineEmits<{
  (e: 'update', selectedValue:any, selectedIndex:any): void
}>();

function selectUpdated(event:Event) {
    const element = event.target as HTMLSelectElement;
    emit('update', element.value, element.options.selectedIndex);
}

const borderedClasses = computed(() => {
    return props.bordered
        ? getInputFieldClasses(states.bordered as inputStates)
        : getInputFieldClasses(states.default as inputStates);
});
const disabledClasses = computed(() => {
    return props.disabled &&
    getInputFieldClasses(states.disabled as inputStates);
});
const requiredClasses = computed(() => {
    return props.required &&
    getInputFieldClasses(states.required as inputStates);
});
</script>
